import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/Posts/PostList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';

const Author = props => {
  const { data, location } = props
  const { wordpressWpUsers, categoryFilter,  wordpressWpSettings, siteSettings } = data;
  const { authorsPosts, name } = wordpressWpUsers;
  const { showAuthor } = siteSettings.options
  if (!authorsPosts) {
    return null;
  }
  const totalCount = (authorsPosts && authorsPosts.length) || 0
  const { title: siteTitle } = wordpressWpSettings
  const title = `${totalCount} post${totalCount === 1 ? '' : 's'} by ${name}`

  // The `authored_wordpress__POST` returns a simple array instead of an array
  // of edges / nodes. We therefore need to convert the array here.
  const posts = authorsPosts.map(post => ({
    node: post,
  }))

  return (
    <Layout className={`page-wrap`}>
      <SEO title={`${decodeEntities(name)} | ${decodeEntities(siteTitle)}`} />
      <PageHeader headerTitle={name} headerSubTitle="Author" location={location} />
      <PostList
      posts={posts}
      title={title}
      categoryFilter={categoryFilter}
      siteMetadata={wordpressWpSettings}
      pathPrefix={`/${wordpressWpSettings.blogSlug}/`}
      showAuthor={showAuthor}
      />
    </Layout>
  )
}

export default Author

export const pageQuery = graphql`
  query AuthorPage($id: String!) {
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    }
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    wordpressWpUsers(id: { eq: $id }) {
      name
      authorsPosts: authored_wordpress__POST {
        ...PostListFields
      }
    }
  }
`
